var Config = {};

Config.defaultWidth = '100%';
Config.defaultHeight = '100%';

Config.setOverflow = {
	/*'overflow': 'hidden',*/
	'position': 'relative',
	'height': '100%'
};

Config.removeOverflow = {
	'overflow': 'visible',
	'position': 'static'
};

Config.addOverflow = {
	'overflow': 'hidden',
	'position': 'fixed'
};

Config.defaultTakeoverStyling = {
	'position': 'fixed',
	'top': '0%',
	'padding': '0',
	'margin': '0',
	'left': '100%',
	'overflow-x': 'hidden',
	'z-index': '1001'
};

Config.internetExplorerDefaultTakeoverStyling = {
	'position': '-ms-page',
	'top': '0%',
	'padding': '0',
	'margin': '0',
	'left': '100%',
	'overflow-x': 'hidden',
	'z-index': '1001'
};

Config.finalTakeoverStyling = {
	'position': 'fixed',
	'top': '0%',
	'padding': '0',
	'margin': '0',
	'left': '0%',
	'overflow-x': 'hidden',
	'z-index': '1001'
};

Config.internetExplorerFinalTakeoverStyling = {
	'position': '-ms-page',
	'top': '0%',
	'padding': '0',
	'margin': '0',
	'left': '0%',
	'overflow-x': 'hidden',
	'z-index': '1001'
};

Config.defaultHeaderStyling = {
	'font-weight': '600',
	'color': '#1c1c1c',
	'font-size': '30px',
	'padding': '20px 0px',
	'width': '100%',
	'background-color': '#fff !important',
	'top': '-5px'
};

Config.defaultBackButtonStyling = {
	'padding-right': '20px',
	'margin-left': '8px',
	'cursor': 'pointer',
	'position': 'relative'
}

module.exports = Config;