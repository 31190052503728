/* Component Dependencies */
var resLinkPropertyRecordsTemplate = require('templates/resLinkPropertyRecords.hbs');
var AriesComponent = require('libs/aries-component');
var DateHandler = require('libs/date-handler');
var RoomGuestHandler = require('libs/room-guests-handler');
var Constants = require('libs/constants');
var ResponsiveUtils = require('utils/responsiveUtils');
var $ = require('jquery');
var Popup = require('libs/popup');
var windowWidth = window.innerWidth;

AriesComponent.create({

  type: 'resLinkPropertyRecords',
  template: {
    'resLinkPropertyRecords': resLinkPropertyRecordsTemplate
  },
  events: {
    'click .js-minus.is-inactive, .js-plus.is-inactive': 'disableScroll'
  },
  bindEvents: function() {
    this.$checkAvailabilityLink = this.$el.find('.check-availability-link');
    this.idPrefix = this.$el.data("id-prefix");
    this.initResponsiveUtils();
    this.initDatePicker();
    this.initRoomGuestHandler();
    this.initHideFlexibleDates();
    this.addTakeoverOnDevice();
    this.initResize();

    if(this.$el.find('.js-hotel-quickview-link-reslink').length) {
      this.quickViewPopupInstance = new Popup({
        selector: '.js-hotel-quickview-link-reslink',
        sourceBlock: '.modal-content',
        alternateCloseMarkup: true
      });
      this.quickViewPopupInstance.register();
    }

    if(this.$el.find('.js-photo-gallery-reslink').length) {
      this.photoGalleryPopupInstance = new Popup({
        selector: '.js-photo-gallery-reslink',
        sourceBlock: '.modal-content',
        alternateCloseMarkup: true
      });
      this.photoGalleryPopupInstance.register();
    }

    $(window).resize(this.initResize.bind(this));
    this.$el.find('.js-view-rate-btn-link').on('click', this.checkAvailability.bind(this));
  },

  initResponsiveUtils: function() {
    this.responsiveUtils = ResponsiveUtils.create({
        isTakeOverRequired: false
    });
    this.responsiveUtils.init();
  },

  initHideFlexibleDates:function(){
    this.$el.find('.l-picker-footer').html(null);
  },
/**
  * This function is used to detect if current device being used is an Android Device
  */
  isAndroidDevice: function() {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1; //&& ua.indexOf("mobile");
  },

  getTakeOverVariation: function _getTakeOverVariation() {
    return false;
  },

  initDatePicker: function _initDatePicker() {
    var _self = this;
    this.$el.find('.js-datepick-container').each(function(index) {
      _self['dateHandler'+index] = new DateHandler({
        $el: $(this),
        $parent: _self,
        suppressTakeOver: true,
        hasFixedDateRange: true,
        isBlurDisable: true
      });
      _self['dateHandler'+index].init();
    });
  },

   /**
  * This function is used to initialize rooms guests handler library for Search Forms
  */
  initRoomGuestHandler: function() {
    var _self = this;
    this.$el.find('.js-rooms-guests').each(function(index) {
      _self['roomGuestHandler'+index] = new RoomGuestHandler({
        $el: $(this),
        $parent: _self,
        suppressTakeOver: true
      });
      _self['roomGuestHandler'+index].init();
    });
  },

  checkAvailability: function _checkAvailability(event) {
    event.preventDefault();
    this.$checkAvailabilityLink.attr('href',
    this.$checkAvailabilityLink.attr('href') +'?isResLink=true&'+ this.formDataMapper($(event.currentTarget).closest('.js-property-record-item'))
    )[0].click();
  },

  formDataMapper: function _formDataMapper($container) {
    var inputSelectors = '[name="roomCount"], [name="numAdultsPerRoom"], [name="childrenCount"]',
      dataSet = Object.assign({}, $container.data()),
      propertyRecordIndex = dataSet.index,
      dateFormat = this.$el.data('date-display-format').toLowerCase(),
      fromDate = this['dateHandler'+propertyRecordIndex].pickaDatePlugin.changeFormat(dateFormat).from,
      toDate = this['dateHandler'+propertyRecordIndex].pickaDatePlugin.changeFormat(dateFormat).to,
      childrenAges = this.$el.find('.js-children-ages')[propertyRecordIndex].value;
      $container.find(inputSelectors).each(function(index, element){
      dataSet[element.name] = element.value;
      delete dataSet.index;
    });
    return ($.param(dataSet)+'&fromDate='+fromDate+'&toDate='+toDate+'&childrenAges='+childrenAges);
  },

  disableScroll: function _disableScroll(e){
    e.preventDefault();
  },

  addTakeoverOnDevice: function _addTakeoverOnDevice() {  
    if (this.responsiveUtils.isMobileDevice || this.responsiveUtils.isTabletDevice) {
      $('body').addClass('is-form-takeover');
    }else{
      $('body').removeClass('is-form-takeover');
    }
  },
  initResize: function _initResize() {
    var newWidth = window.innerWidth;
    if(newWidth !== windowWidth){
      this.addTakeoverOnDevice();
      originalWidth = newWidth;
    }
  }
});