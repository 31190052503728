
/**
* This plugin is used to add the scrollbar on dropdowns, list of target elements required for scrollbar
*/
var ComponentMapper = require('./component-mapper');
var scrollBar = require('jquery.scrollbar');
var _ = require('lodash');

var properties = {
	$scrollBarTarget: '',
	options: {
		class: {
			scrollerClass: 'scrollbar-outer'
		}
	},
	/**
	* Initialize scroller plugin on given target elements
	**/
	init: function() {
		var _self = this;
		this.scrollerClass = _self.$className || _self.options.class.scrollerClass;

		if(this.$scrollBarTarget.length) {
			this.$scrollBarTarget
				.addClass(this.scrollerClass)
				.scrollbar();
		}//end of if block
	},//end of init
	/**
	* Destroy scroller plugin on given target elements
	**/
	destroy: function() {
		var _self = this;

		if(this.$scrollBarTarget.length) {
			this.$scrollBarTarget.removeClass(this.scrollerClass)
				.scrollbar("destroy");
		}
	}
};

module.exports = {
	create: function(props) {
		var obj = _.cloneDeep(properties);
		var scrollBarInstance = ComponentMapper.extend(obj);
		return new scrollBarInstance(props);
	}
};