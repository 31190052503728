var $ = require('jquery');
var _ = require('lodash');
var PickADatePlugin = require('./pickaDateHandler');
var Picker = require('./picker');
var PageTakeover = require('libs/pageTakeover');
var Constants = require('libs/constants');
var Config = require('libs/config');
var ComponentMapper = require('./component-mapper');
var Popup = require('libs/popup');

var properties = {
  SEC_IN_DAY: 86400000,
  allowPastDates: this.allowPastDates,
  options: {
    maxDays: 355,
    pickerActive: 'picker__input--active',
    inRangeClass: 't-date-inrange',
    startDayClass: 't-date-start',
    endDayClass: 't-date-end',
    nightsDisplay: '.js-no-ofnights',
    defaultDate: 'js-defaultdate',
    iserror: 'is-error',
    styleClass: 't-box-border',
    pickerFrame: '.picker__frame',
    alwaysOnTop: false,
    alwaysOnTopClass: 'l-pos-flex',
    container: '.js-date-pick-container',
    contActive: 'is-active',
    flexdateTrigger: '.js-toggle-flexdate',
    dropdownTrigger: '.m-icon-trigger',
    dropdownActive: 't-icon-arrow-up',
    dropdownInActive: 't-icon-arrow-down',
    inputs: {
      submitFrom: '.js-submit-from',
      submitTo: '.js-submit-to',
      flexCheck: '.js-flex-check',
      flexLength: '.js-flex-input',
      fromToDate: '.js-date-from-to',
      fromDate: '.js-date-from',
      toDate: '.js-date-to'
    },
    lang: Picker.lang,
    locale: {},
    txtNight: '',
    txtNights: '',
    txtFlexDate: '',
    txtSpecificDate: ''
  },
  fromTo: ['from', 'to'],
  pickerState: 'from',
  flexdateState: 'date',
  footer: {},
  flex: {},
  closeOnDocumentClick: true,
  closeOnFormFieldsFocus: true,
  events: {
    'change .js-date-from,.js-date-to': 'updateDate',
    'keydown .js-date-from, .js-date-to': 'keyEvents',
    'click .js-toggle-picker': 'toggleBarTrigger',
    'keydown .js-toggle-picker': 'toggleBarTrigger'
  },
  init: function() {
    this.resetOptions();
    this.setProperties();
    //flag for non dated search
    this.isNonDatedSearch = this.$el.closest('.l-date-picker').data('is-non-dated-search');
    this.dateFromData = this.$el.find('.js-date-from').data();

    this.initPickaDatePlugin();
    if (!this.singleDate) {
      this.bindEvents();
      this.subscribeDOMEvents();
      this.initFlexDate();
      this.initPickerFooter();
      this.toggleFlexDate(this, (this.flex.$check.attr('checked')) ? 'flex' : 'date');
      if (this.$parent.responsiveUtils && this.$parent.responsiveUtils && this.$parent.responsiveUtils.isMobileOrTablet()){
        this.loadFlexibleDateForTakeover();
      }else {
        this.initialFlexDateWidth = 'auto';
        this.initialMonthBoxWidth = this.$el.find('.js-flex-months-holder').width();
      }
      if(!this.suppressTakeOver) {
        this.createTakeOverScreen(this.$el.find('.picker'), this.$el.data('takeover-label'), this.$parent.$el.data('done-button-label'));
      }
      if (this.suppressTakeOver) {
        this.takeoverScreen = {
          isActive:false
        }
        return;
      }

      //Android specific fix
      if (typeof this.$parent.isAndroidDevice === 'function') {
        var isAndroid = this.$parent.isAndroidDevice();
        if(isAndroid) {
          var _self = this;
          this.$el.find('#length-of-stay-input').on('focus', function() {
            _self.$el.find('.m-flex-frame-wrapper').css(Config.ANDROID_INPUT_FIX);
          }).on('blur', function() {
            _self.$el.find('.m-flex-frame-wrapper').css(Config.REMOVE_ANDROID_INPUT_FIX);
          });
        }
      }

      //micorelibs.js manipulation's fix
      this.$el.find('.js-stepper-input').removeClass('is-hidden');
    } else {
      var self = this;
      if(self.bindSingleDateEvents) {
        self.from.$input.on('focus', function(evt) {
          self.from.$input.pickadate('picker').open();
        });
        self.$el.find('.js-date-from').on('blur', this.updateSingleDate.bind(this));
      }
    }
    
  },
  updateSingleDate: function(event) {
    var self = this,
        parsedDate = self.parseDate(event.target.value) ? self.parseDate(event.target.value) : self.parseDate( $(event.target).siblings("input[name=checkoutEnteredDate]").val() || $(event.target).siblings("input[name=checkInEnteredDate]").val());
    if (parsedDate && !$(event.relatedTarget).hasClass('picker__holder')) {
      self.from.$input.pickadate('picker').set('select', parsedDate,{ muted: true });
    } 
    else if (!parsedDate) {
      self.from.$input.val('');
    }
  },
  updateActiveUnderline: function(close) {
    var _self= this;
    var activeUnderline = this.$el.find('.l-toggle-active');

    setTimeout(function(){
      if (close) {
        activeUnderline.css( {left:_self.from.$input.position().left, width:0} );
      } else if ( activeUnderline  && activeUnderline.length>0 ) {
        var activeInput =  _self.from.$input.add(_self.to.$input).filter( ':visible.' + _self.options.pickerActive );
        if (activeInput.length>0) {
          activeUnderline.css( {left:activeInput.position().left, width:activeInput.width()} );
        } else {
          activeUnderline.css( {left:_self.from.$input.position().left, width:0} );
        }
      }
    },1);
  },
  createTakeOverScreen: function($el, label, buttonLabel) {

    var _self = this;
    var TakeOverConfig = Config.TAKEOVER_VARIATION[this.$parent.getTakeOverVariation()];
    var takeoverVariation = this.$parent.getTakeOverVariation();
    this.takeoverScreen = PageTakeover.create({
      $el: $el,
      headerLabel: label,
      transition_speed: Constants.SEARCH_FORM.TAKE_OVER_SPEED,
      skipDimensions: true,
      keepOverflowHidden: TakeOverConfig.KEEP_OVERFLOW_HIDDEN,
      customHeader: function() {
        this.$el.prepend('<div class="page-take-over-header"><span class="back-btn"></span>'+this.headerLabel+'<a class="done_button" href="#">'+buttonLabel+'</a></div>');
        if (!_self.addCustomHeaderStyle) {
          this.$el.find('.page-take-over-header').css(Config.TAKEOVER_HEADER_STYLING);
        }
        this.$el.find('.done_button').on('click', _.bind(_self.hideTakeoverScreen, _self));
      },
      beforeShow: function() {
        _self.$el.addClass(_self.options.contActive);
        _self.$dateFromToPicker.$holder.show();
        if (_self.$parent.responsiveUtils && !_self.$parent.responsiveUtils.isDevice()) {
          _self.flex.$monthTrigger.filter('.is-selected').focus();
        }
        _self.$dateFromToPicker.$root.addClass('picker--opened').attr('aria-hidden', 'false');
        if (takeoverVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) {
          _self.$el.closest('.mfp-wrap').css('overflow', 'hidden');
        }
        if (_self.flexdateState !== 'flex') {
          _self.$el.find('.date-label,.selected-dates').show();
        } else {
          _self.$el.find('.date-label,.selected-dates').hide();
        }
      },
      afterHide: function() {
        _self.$dateFromToPicker.$root.removeClass('picker--opened').removeClass('picker--focused');
        _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
        _self.$el.removeClass(_self.options.contActive);
        _self.$dateFromToPicker.$holder.slideUp(100, function() {
          _self.from.$input.removeClass(_self.options.pickerActive);
          _self.to.$input.removeClass(_self.options.pickerActive);
          _self.$el.removeClass(_self.options.contActive);
          _self.from.$input.trigger('blur');
        });
        if (takeoverVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) {
          _self.$el.closest('.mfp-wrap').css({'overflow-x': 'hidden','overflow-y': 'scroll'});
        }
        _.defer(function() {
          _self.$dateFromToPicker.$root.removeAttr('style');
        })
      },
      backButtonCallback: function() {
        _self.$el.find('.date-label,.selected-dates').hide();
        _self.$el.find('.js-flex-months-holder').css('width','100%');
      }
    });
  },
  hideTakeoverScreen: function(e) {
    if (this.suppressTakeOver) {return;}
    if (e) {
      e.preventDefault();
    }
    this.$el.find('.date-label,.selected-dates').hide();
    this.takeoverScreen.hide();
  },
  addTakeover: function() {
    if (this.suppressTakeOver) {return;}
    if (this.$dateFromToPicker.$root.hasClass('picker--opened')) {
      this.takeoverScreen.reload();
      if (this.$parent.responsiveUtils && this.$parent.responsiveUtils.isPortrait()) {
        this.$el.find('.picker__holder').css('position', 'fixed');
      }else {
        this.$el.find('.picker__holder').css('position', 'relative');
      }
      this.$el.find('.page-take-over-header').show();
    }
    this.loadFlexibleDateForTakeover();
  },
  removeTakeover: function() {
    if (this.suppressTakeOver) {return;}
    var _self = this;
    if (this.$dateFromToPicker.$root.hasClass('picker--opened')) {
      this.$el.find('.date-label,.selected-dates').hide();
      this.takeoverScreen.remove();
      this.$dateFromToPicker.$root.addClass('picker--opened').addClass('picker--focused');
      this.$dropdownIcon.addClass(_self.options.dropdownActive).removeClass(_self.options.dropdownInActive);
      this.$el.addClass(_self.options.contActive);
      this.$el.find('.js-flex-months-holder').css('width','100%');
      this.openFrom(null, _self);
      setTimeout(function() {
        _self.$el.find('.picker__holder').css('position', 'absolute').show();
        _self.$parent.$el.find('.js-datepick-container').addClass('is-active');
      }, 500)
    }
    _self.$el.find('.picker__holder').css('position', 'absolute');
    this.loadFlexibleDateForTakeover(true);
  },
  loadFlexibleDateForTakeover: function(revert) {
    if (this.suppressTakeOver) {return;}
    if (!this.initialFlexDateWidth) {
      this.initialFlexDateWidth = 'auto';
      this.initialMonthBoxWidth = this.$el.find('.js-flex-months-holder').width();
    }
    if(!revert) {
      var numberofMonths = this.$el.find('.js-flex-months-holder').children().length;
      var outerWidth = this.initialMonthBoxWidth;
      var marginLeft = parseInt($(this.$el.find('.js-flex-months-holder a')[0]).css('margin-left'));
      var marginRight = parseInt($(this.$el.find('.js-flex-months-holder a')[0]).css('margin-right'));
      var fixConstant = 20;

      var fixWidth = (numberofMonths * (outerWidth + marginLeft + marginRight)) + fixConstant;
      this.$el.find('.js-flex-months-holder').width(fixWidth);
    }else {
      this.$el.find('.js-flex-months-holder').width(this.initialFlexDateWidth);
    }
  },
  resetOptions: function() {
    this.options.locale = {};
    this.inputNames = {};
    this.options.invalidDateMsg = '';
    this.from = {};
    this.from.$label = '';
    if (!this.singleDate) {
      this.flex = {};
      this.footer = {};
      this.$dateFromTo = '';
      this.$flexInput = '';
      this.to = {};
      this.to.$label = '';
      this.$dropdownIcon = '';
    }
  },
  bindEvents: function() {
    if (!this.singleDate) {
      this.initCalendarHeader();
    }
    //micorelib conflict fixes
    this.$el.find('.m-flex-frame .l-stepper').removeClass('is-hidden');
    this.$el.find('#'+ this.$parent.idPrefix +'_hotel-fromDate').prop('readonly', false);
    this.$el.find('#'+ this.$parent.idPrefix +'_hotel-toDate').prop('readonly', false);
    this.$el.find('#'+ this.$parent.idPrefix +'_hotel-fromDate_root').remove();
    this.$el.find('#'+ this.$parent.idPrefix +'_hotel-toDate_root').remove();
    //ends
    this.$el.find('.js-flex-display').on('focus', _.bind(this.openFlex, this));
    this.$el.find('.js-date-from').on('focus', _.bind(this.openFrom, this));
    this.$el.find('.js-date-to').on('focus', _.bind(this.openTo, this));

    // disable document click close
    if(this.closeOnDocumentClick) {
      $(document).on('click', _.bind(function(evt) {
        this.close(this, evt);
      }, this));
      $(document).on( 'keyup', _.bind(function(evt) {
        if( evt.which == 9 ) {
          this.close(this, evt);
        }
      }, this));
    }
    if(!this.$parent.isAddSegmentTile) {
      this.$el.parents('.mfp-content').on('click', _.bind(function(evt) {
        this.close(this, evt);
      }, this));
    }

    if(this.closeOnFormFieldsFocus) {
      this.$parent.$el.find('input[type!=\'hidden\'], .js-date-clear, a, div[tabindex = \'0\']').off('focusin focusout').on('focusin focusout', _.bind(function(evt) {
        this.close(this, evt);
      }, this));
    }
  },
  setProperties: function() {
    if (this.singleDate) {
      this.from={
       $input:this.$el.find(this.options.inputs.fromDate)
      }
    }else {
      this.inputNames = {
        flexi: this.$el.find(this.options.inputs.flexibleDate).prop('name')
      };
      this.$dateFromTo = this.$el.find(this.options.inputs.fromToDate);
      this.options.invalidDateMsg = this.$dateFromTo.data().invalidmsg;
      this.$flexInput = this.$el.find(this.options.inputs.flexibleDate);
      this.from = {
        $input: this.$el.find(this.options.inputs.fromDate),
        $submit: this.$el.find(this.options.inputs.submitFrom)
      };
      this.to = {
        $input: this.$el.find(this.options.inputs.toDate),
        $submit: this.$el.find(this.options.inputs.submitTo)
      };
      this.from.$label = this.$el.find('label[for="'+this.from.$input.attr('id')+'"]').html();
      this.to.$label = this.$el.find('label[for="'+this.to.$input.attr('id')+'"]').html();
      this.$dropdownIcon = this.$el.find(this.options.dropdownTrigger);
      this.to.$input.addClass('is-disabled');
    }
  },
  initPickaDatePlugin: function() {
    this.pickaDatePlugin = new PickADatePlugin();
    this.pickaDatePlugin.init(this);
  },
  initCalendarHeader: function() {
    var data = '<div class="date-label">'+this.$el.data('check-in-label')+' - '+this.$el.data('check-out-label')+'</div>',
        indexSufix = this.$el.parents('.property-record-item').data('index')>-1 ? '_'+this.$el.parents('.js-property-record-item').data('index') : '';
    data += '<div class="selected-dates">';
    data += '<label class="l-date is-hidden-label" for="' + this.$parent.idPrefix +'_check-in' +indexSufix+'">'+this.$el.data('check-in-label')+'</label>';
    data += '<input type="text" id="' + this.$parent.idPrefix +'_check-in'+indexSufix+'" class="check-in js-date-from l-h-field-input" maxlength="17" autocomplete="off" value="' + this.from.$input.val() + '" placeholder='+this.$el.data('check-in-label')+'></input>';
    data += ' - ';
    data += '<label class="l-date is-hidden-label" for="' + this.$parent.idPrefix +'_check-out'+indexSufix+'">'+this.$el.data('check-out-label')+'</label>';
    data += '<input type="text" id="' + this.$parent.idPrefix +'_check-out'+indexSufix+'" class="check-out js-date-to l-h-field-input" maxlength="17" autocomplete="off" value="' + this.to.$input.val() + '" placeholder='+this.$el.data('check-out-label')+'></input>';
    data += '</div>';
    if(!this.$parent.$el.find(".js-red-eye").length) {
      this.$dateFromToPicker.$frame.prepend(data);
    }
  },
  updateHeader: function(type) {
    this.$el.find('.selected-dates .check-in').val(this.from.$input.val());
    this.$el.find('.selected-dates .check-out').val(this.to.$input.val());
  },
  initFlexDate: function() {
    var _self = this;
    var monthHtml = '';
    var m = _self.options.dates.fromMin.getMonth();
    var y = _self.options.dates.fromMin.getFullYear();
    var titleInitials = ( _self.$el.find('.js-date-pick-container').data('options').flexibleInMonthTitleInitial ?  _self.$el.find('.js-date-pick-container').data('options').flexibleInMonthTitleInitial+' ' : 'Month of ');
    var strY = ' ' + y;
    var i;
    var yearDiff = _self.options.dates.fromMin.getYear() - _self.options.dates.toMax.getYear();
    var noOfMonths;
    var countryCode = this.$el.data('country-code');
    var icon = "<span class='t-icon t-icon-check is-hidden'></span>"

    _self.flex.$root = _self.$el.find('.m-flex-frame-wrapper').appendTo(_self.$dateFromToPicker.$holder);

    noOfMonths = 1 - (yearDiff * 12) - m + _self.options.dates.toMax.getMonth();

    for (i = 0; i < noOfMonths; i++) {
      monthHtml += '<a href="#" data-month="' + m + '" data-year ="' + y + '"';
      monthHtml += ' title="' + titleInitials +  _self.options.locale.monthsFull[m] + strY + '"';
      monthHtml += ' class="js-flex-month l-s2-option t-blk t-no-decor l-s-col-2">';
      monthHtml += ((countryCode === 'JP') ? _self.options.locale.monthsFull[m] : _self.options.locale.monthsShort[m]) + strY + icon + '</a>';
      m++;
      if (m === 12) {
        y++;
        strY = ' ' + y;
        m = 0;
      } else {
        strY = '';
      }
    }

    _self.flex.$root.find('.js-flex-months-holder').html(monthHtml);

    _self.flex.$check = _self.$el.find(_self.options.inputs.flexCheck);
    _self.flex.$length = _self.$el.find(_self.options.inputs.flexLength);
    

    var flexNights = parseInt(this.$el.find('.js-date-pick-container').data('options').flexibleNight);
    _self.$el.find('.js-stepper-input').val((flexNights > 1) ? (flexNights + ' ' + _self.options.txtNights) : (flexNights + ' ' + _self.options.txtNight));
    _self.flex.$length.val(flexNights);

    _self.flex.$display = _self.$el.find('.js-flex-display');
    _self.flex.displayLabel = _self.flex.$display.data('label');

    _self.flex.$monthTrigger = _self.flex.$root.find('.js-flex-month')
            .on('click', function(evt) {
              _self.onMonthChg(evt, _self);
            });

    _self.flex.$plus = _self.flex.$root.find('.js-plus')
            .on('click', function(evt) {
              _self.onFlexStepperChg(evt, _self, true);
            });

    _self.flex.$minus = _self.flex.$root.find('.js-minus')
            .on('click', function(evt) {
              _self.onFlexStepperChg(evt, _self);
            });
    _self.flex.$stepperVal = _self.flex.$root.find('.js-stepper-val')
    _self.flex.$stepperInput = _self.flex.$root.find('.js-stepper-input')
            .on('change', function(evt) {
              _self.setStepperVal(_self.$el.find(evt.target).val(), _self);
            }).on('keydown', function(evt) {
              /*overrider picker backspace keydown*/
              evt.stopPropagation();
            });

    if (_self.pickerState === 'flex') {
      _self.displayFlex();
    }

    if ($('html').hasClass('touch')) {
      _self.flex.$root.addClass('has-slider');
    }
  },
  clearDates: function(_self){
    _self.flex.$length.val("1");
    $(_self.fromTo).each(function(i, o) {
      _self[o].$submit.val('');
      _self[o].$input.val('');
      _self[o].date = null;
    });
    _self.openFrom(null, _self);
    if (_self.$parent.responsiveUtils && _self.$parent.responsiveUtils.isMobileOrTablet()) {
      $('html,body').scrollTop(0);
    }
    
    _self.updateHeader();
  },
  initPickerFooter: function() {
    var _self = this;

    var footerHtml = '<div class="l-picker-footer">';
    footerHtml += (_self.$el.find('.js-date-pick-container').data('options').flexdates ? (
      '<a href="#" class="js-toggle-flexdate l-float-left m-button m-button-secondary">' + 
      _self.$el.find('.js-date-pick-container').data('options').flexdates + '</a>'):'');
    footerHtml += (_self.options.txtReset ? (
      '<a href="#" class="t-control-link l-float-right l-h-small-button l-flex js-flex-reset">' + 
      _self.options.txtReset + '</a>') : '');
    footerHtml += (_self.options.txtClear ? ('<a href="#" class="t-control-link l-float-right l-h-small-button l-date js-date-clear">' +
        _self.options.txtClear + '</a>') : '');
    footerHtml += '</div>';
    
    _self.footer.$root = $(footerHtml).appendTo(_self.$dateFromToPicker.$holder);
    _self.$dateFromToPicker.$root.find('.js-date-clear').on('click', function(evt){ 
      evt.preventDefault();
      _self.clearDates(_self); 
    });
    _self.$dateFromToPicker.$root.find('.js-flex-done').on('click', function(evt) {
      evt.preventDefault();
      _self.close(_self);
      setTimeout(function() {
        _self.$dateFromToPicker.close(true);
        _self.$parent.$el.find('.l-rooms-guests-trigger-box').focus();
      }, 300);
    });
    _self.$dateFromToPicker.$root.find('.js-flex-reset').on('click', function(evt) {
      evt.preventDefault();
      _self.clearDates(_self);
      _self.toggleFlexDate(_self, 'flex', true);
    });
    _self.$toggleFlex = _self.$el.find(_self.options.flexdateTrigger).on('click', function(evt) {
      evt.preventDefault();
      _self.toggleFlexDate(_self, null, true);
    });
    // in case it is a addSegment datepicker, add a reset button and last inventory date in footer
    if(_self.$parent.isAddSegmentTile) {
      var inventoryLabel = this.$el.find('.inventory-date').data('inventory').label;
      var inventoryValue = this.$el.find('.inventory-date').data('inventory').value;
      var data="<div class='inventory-date-container t-font-m'><span class='inventory-label t-font-weight-bold'>" + inventoryLabel +" </span><span class='date-of-inventory'>"+ inventoryValue +"</span></div>";
      var resetLabel = _self.$parent.$el.find('#calendar-reset-label').val();
      data += "<div class='l-float-right'><span class='reset-date-button t-font-weight-semibold t-font-m t-cursor-pointer'>"+resetLabel+"</span></div>";      
      if(_self.$el.find('.reset-date-button').length === 0) {
        this.$el.find('.l-picker-footer').html(data);
      }
      _self.$el.find('.reset-date-button').on('click', _.bind(_self.resetDateHandler, this));
    }
  },
  /**
  * Resets the calendar to the initial chekin checkout dates
  **/
  resetDateHandler: function (event) {
    var _self = this;
    var resetCheckin = $('#checkin-reset-date').val(),
        resetCheckout = $('#checkout-reset-date').val();
    this.from.date = new Date(resetCheckin);
    this.from.$submit.val(resetCheckin);
    this.from.$submit.data('value', resetCheckin);
    this.pickaDatePlugin.setDateOnFormatChange(_self, 'from');
    this.to.date = new Date(resetCheckout);
    this.to.$submit.val(resetCheckout);
    this.pickaDatePlugin.setDateOnFormatChange(_self, 'to');
    this.pickaDatePlugin.handleFormat();
    if (event) {
      this.$el.find('.js-date-from').focus();
    }
  },
  /**
  * Accepts callback onFlexibleDateClick
  **/
  toggleFlexDate: function(_self, newFlexState, open) {
    /*On click of the date/flex field toggle open calendar or flex month picker*/
    var isPickerHidden = 'is-picker-hidden';
    var isFlexHidden = 'is-flex-hidden';

    _self.flexdateState = newFlexState || (_self.flexdateState === 'date' ? 'flex' : 'date');

    if(this.onFlexibleDateClick) {
      this.onFlexibleDateClick();
    }

    if (_self.flexdateState === 'flex') {
      if (_self.$parent.responsiveUtils && _self.$parent.responsiveUtils.isMobileOrTablet() && _self.$el.find('.picker').hasClass('page-take-over')) {
        _self.$el.find('.date-label,.selected-dates').hide();
        $('html,body').scrollTop(0);
      }
      /*open flex month picker*/
      _self.flex.$check.attr('checked', true);

      _self.$el.addClass(isPickerHidden).removeClass(isFlexHidden)
          .find('.js-toggle-picker');
      _self.$dateFromToPicker.$frame.hide();
      _self.flex.$root.show();
      _self.footer.$root.find(_self.options.flexdateTrigger).html(_self.$el.find('.js-date-pick-container').data('options').specificdates);
      if (!_self.from.date) {
        _self.pickerState = 'from';
        _self.$dateFromToPicker.set('select', _self.options.dates.todaysDate);
      }
      _self.displayFlex();
      _self.checkFlexStepper();
      if (open) {
        _self.pickerState = 'to';
        _self.$dateFromToPicker.set('select', _self.datePlusDays(_self.from.date, _self.flex.$length.val()), { muted: true });
      }
      if (_self.$parent.responsiveUtils && !_self.$parent.responsiveUtils.isDevice()) {
        _self.flex.$monthTrigger.filter('.is-selected').focus();
      }
      /*To select to-date initially*/
      _self.flex.$monthTrigger.filter('.is-selected').trigger('click');
    } else {
      if (_self.$parent.responsiveUtils && _self.$parent.responsiveUtils.isMobileOrTablet() && _self.$el.find('.picker').hasClass('page-take-over')) {
        _self.$el.find('.date-label,.selected-dates').show();
        $('html,body').scrollTop(0);
      }
        /*open date picker */
      _self.flex.$check.attr('checked', false);

      _self.$el.addClass(isFlexHidden).removeClass(isPickerHidden)
          .find('.js-toggle-picker');
      _self.flex.$root.hide();
      _self.$dateFromToPicker.$frame.show();
      _self.footer.$root.find(_self.options.flexdateTrigger).html(_self.$el.find('.js-date-pick-container').data('options').flexdates);

      if (open) {
        _self.pickerState = 'to';
        _self.$dateFromToPicker.set('select', _self.datePlusDays(_self.from.date, _self.flex.$length.val()), { muted: true });
        _self.openFrom(null, _self);
      }
    }
    _self.updateActiveUnderline();
  },
  displayFlex: function() {
    var _self = this,
        days = _self.flex.$length.val(),
        selectedM = _self.from.date && _self.from.date.getMonth(),
        selectedY = _self.from.date && _self.from.date.getFullYear();

    _self.flex.$monthTrigger.filter('.is-selected').removeClass('is-selected').removeAttr('aria-selected');
    _self.flex.$monthTrigger.filter('[data-month="' + selectedM + '"]').filter('[data-year="' + selectedY + '"]')
        .addClass('is-selected').attr('aria-selected', 'true');

    _self.flex.$display.html(
        _self.flex.displayLabel + ' ' + _self.options.locale.monthsFull[selectedM] + ' - ' + days + ' ' + ((days == 1) ? _self.options.txtNight : _self.options.txtNights)
    );
    _self.flex.$stepperInput.val((days > 1) ? (days + ' ' + _self.options.txtNights): (days + ' ' +_self.options.txtNight));
    _self.flex.$stepperVal.text(days);
  },
  checkFlexStepper: function(stepVal, _self) {
    _self = _self || this;
    var firstVal = _self.flex.$length.find(':first-child').val(),
        lastVal = _self.flex.$length.find(':last-child').val(),
        inActive = 'is-inactive';

    firstVal = parseInt(firstVal, 10);
    lastVal = parseInt(lastVal, 10);
    stepVal = stepVal || parseInt(_self.flex.$stepperInput.val());
    stepVal = (isNaN(parseInt(stepVal, 10))) ? 1 : parseInt(stepVal, 10);

    _self.flex.$plus.add(_self.flex.$minus).removeClass(inActive);

    if (stepVal >= lastVal) {
      stepVal = lastVal;
      _self.flex.$plus.addClass(inActive);
    } else if (stepVal <= firstVal) {
      stepVal = firstVal;
      _self.flex.$minus.addClass(inActive);
    }

    return stepVal;
  },
  datePlusDays: function(dt, days) {
    days = Number(days);
    var newDate = new Date(dt);
    return newDate.setDate(newDate.getDate() + days);
  },

  onFlexStepperChg: function(evt, _self, add) {
    evt.preventDefault();
    if (_self.$parent.responsiveUtils && _self.$parent.responsiveUtils.isMobileOrTablet() && _self.$el.find('.picker').hasClass('page-take-over')) {
      $('html,body').scrollTop(0);
    }
    if (_self.$el.find(evt.target).hasClass('is-inactive')) {
      return;
    }
    var stepVal = _self.flex.$length.val();
    stepVal = (add) ? ++stepVal : --stepVal;
    _self.setStepperVal(stepVal, _self);
    _self.$el.find(evt.target).focus();
  },

  setStepperVal: function(stepVal, _self) {
    var fromDate = _self.from.date;

    stepVal = _self.checkFlexStepper(stepVal, _self);

    if (stepVal) {
      _self.pickerState = 'to';
      _self.$dateFromToPicker.set('select', _self.datePlusDays(fromDate, stepVal), { muted: 'true' });
      _self.to.$submit.val(_self.$dateFromToPicker._hidden.value);
    }

    _self.flex.$stepperInput.val((parseInt(stepVal) > 1) ? (stepVal + ' ' + _self.options.txtNights):(stepVal + ' ' + _self.options.txtNight));
    _self.flex.$length.val(stepVal);

    _self.displayFlex();
  },

  onMonthChg: function(evt, _self) {
    var $evt = _self.$el.find(evt.target),
        m = $evt.data().month,
        y = $evt.data().year;
    var currentDate = new Date();
    var selectedDate = 1;
    evt.preventDefault();

    $evt.parent().find('.is-selected').removeClass('is-selected');
    $evt.addClass('is-selected');

    _self.pickerState = 'from';
    if ($('.js-red-eye').length && _self.flexdateState === 'flex' && currentDate.getMonth() === m) {
      selectedDate = currentDate.getDate();
    }
    _self.$dateFromToPicker.set({
        'min': this.options.dates.fromMin,
        'max': this.options.dates.fromMax,
        'select': new Date(y, m, selectedDate)
      });  
    _self.displayFlex();

    //to set the focus only if the event is triggered through interaction
    if(evt.originalEvent){
      _self.flex.$stepperInput.focus();
    }
  },
  /**
  * This function is used to expand HWS search form on click of date
  */
  expandSearchHWS: function() {
    if (typeof this.expandSearchForm === 'function') {
      if (this.$parent.responsiveUtils && this.$parent.responsiveUtils.isMobileOrTablet() && this.$parent.$el.hasClass('is-collapsed')) {
        this.expandSearchForm();
        return;
      } else {
        this.expandSearchForm();
      }
    }
  },
  openFrom: function(e) {
    if(this.$parent.isAddSegmentTile) {
      this.$parent.$el.addClass('opened-picker');
    }
    this.expandSearchHWS();
    this.pickerState = 'from';
    this.openCalendar(e);
  },
  openTo: function(e) {
    if (!e || (e && !$(e.target).hasClass('is-disabled'))) {
      if(this.$parent.isAddSegmentTile) {
        this.$parent.$el.addClass('opened-picker');
      }
      this.expandSearchHWS();
      this.pickerState = 'to';
      this.openCalendar(e);
    } else if(this.$parent.responsiveUtils && !this.$parent.responsiveUtils.isMobileOrTablet()) {
      this.from.$input.focus();
    }
  },
  openFlex: function(e) {
    this.expandSearchHWS();
    this.pickerState = 'flex';
    this.openCalendar(e);
    if (this.$parent.responsiveUtils && !this.$parent.responsiveUtils.isDevice()) {
      this.flex.$monthTrigger.filter('.is-selected').focus();
    }
  },
  openCalendar: function(e) {
    var _self = this;
    var min, max;
    var redEyeFlag = $('.js-red-eye').length ? true : false;

    if (_self.$parent.responsiveUtils && !_self.$parent.responsiveUtils.isDevice() && _self.$parent.$el.closest('.is-sticky-top').length ) {
      _self.pubsub.publish('HIDE_HWS_NAVIGATION_BAR');
    }
    if (_self.pickerState === 'to') {
      if(redEyeFlag && _self.from.date && _self.from.date < _self.options.dates.todaysDate) {
        min = _self.options.dates.todaysDate;
      } else if (_self.from.date && _self.from.date > _self.options.dates.todaysDate) {
        min = _self.from.date;
      } else if (_self.hasFixedDateRange && _self.options.dates.toMin) {
        min = _self.options.dates.toMin;
      }else {
        if(this.allowPastDates) {
          min = _self.options.dates.fromMin;
        } else {
          min = 1;
        }
      }
      max = _self.options.dates.toMax;
    } else {
      min = _self.options.dates.fromMin;
      max = _self.options.dates.fromMax;
    }

    if (_self.dateFromData.showKGroupDate) {
      max = new Date(_self.dateFromData.kGroupDate);
    }

    var pickerOptions = {
      'min': min,
      'max': max,
      'select': (_self.pickerState === 'to') ? _self.to.date : _self.from.date,
    };

    if(this.disableDates && this.disableDates.from && this.disableDates.to) {
      var from = this.disableDates.from;
      var to = this.disableDates.to;
      pickerOptions.disable = [ {from: from, to: to} ];
    }

    _self.$dateFromToPicker.set( pickerOptions, {muted: true});
    if ((_self.$parent.responsiveUtils && _self.$parent.responsiveUtils.isMobileOrTablet() && !this.suppressTakeOver) && !_self.$el.find('.picker').hasClass('page-take-over')) {
      if (e) {
        if (_self.takeoverScreen) {
          _self.takeoverScreen.init();  
        }
        _self.$el.find('.js-date-from,.js-date-to').blur();
      }
    }else {
      _self.$el.addClass(_self.options.contActive);
      if (!_self.$dateFromToPicker.$holder.is(':visible')) {
        _self.$dateFromToPicker.$holder.slideDown('medium');
      }
    }
    _self.$dateFromToPicker.open(false);
    if(redEyeFlag) {
      var yesterday = new Date(),
          dd,
          mm,
          yy,
          redDate;
      yesterday.setDate(yesterday.getDate() - 1);
      dd = yesterday.getDate();
      mm = yesterday.getMonth();
      yy = yesterday.getFullYear();
      redDate = new Date(yy, mm, dd, 0, 0, 0, 0);

      $('.picker__table').find("[data-pick='" + redDate.getTime() + "']").each(function(index, item){
        var $item = $(item);
        if(!(_self.from.date < _self.options.dates.todaysDate) ) {
          if(!$item.hasClass('t-date-start') && $('.js-red-eye').length) {
            $item.addClass('l-red-eye-date');
          }
        }
      });
    }
    this.updateActiveUnderline();
    _self.$el.find('.js-date-to').removeClass('is-disabled');
  },
  toggleLabel: function(showInput, _self) {
    _self = _self || this;
    if (!(_self.from.$label.length && _self.to.$label.length)) {
      return;
    }
    var isHidden = 'is-hidden-label',
        isVisible = 'l-h-field-input';
    _self.$el.find(_self.fromTo).each(function(i, o) {
      if (_self[o].$submit.val() || _self[o].$input.is(':focus') || showInput === o) {
        _self[o].$input.removeClass(isHidden).addClass(isVisible);
        _self[o].$label.removeClass(isVisible).addClass(isHidden);
      } else {
        _self[o].$input.removeClass(isVisible).addClass(isHidden);
        _self[o].$label.removeClass(isHidden).addClass(isVisible);
        _self[o].$input.val('');
      }
    });
  },

  parseDate: function(str) {
    var parsedDate, year;

    /*parse according to the locale eg. mm/dd/yy dd/mm/yy etc..*/
    try {
      parsedDate = $locale.locale('parseDate', str);  
    } catch(e) {
      parsedDate = null
    }
    // Fix added for parsing short format date on viewport between 480 to 1024
    if ($(window).width() > 480 && $(window).width() < 1024) {
      if (_.isNull(parsedDate) && str.split(',').length == 2) {
        str+= "," + this[this.pickerState].date.getFullYear();
      }
    }
    /*if null parse as long format*/
    parsedDate = parsedDate || Date.parse(str);

    if (parsedDate) {
      parsedDate = new Date(parsedDate);
       year = parsedDate.getFullYear();
      if (year < 2000) {
        parsedDate.setYear(year + 100);
      }
    }

    return parsedDate;
  },

  updateDate: function(evt) {
    if (this.preventChangePropagation === true) {
      return;
    }
    var parsedDate = this.parseDate(evt.target.value);

    if (parsedDate) {
      this.$dateFromToPicker.set('select', parsedDate);
    } else if (!parsedDate && !evt.target.value) {
      this[this.pickerState].$submit.val('');
      this[this.pickerState].$input.val('');
      this[this.pickerState].date = null;
    } else {
      alert(this.options.invalidDateMsg);
      this[this.pickerState].$submit.val('');
      this[this.pickerState].date = null;
      this.$el.find(evt.target).select();
    }
    this.updateHeader();
    this.pickaDatePlugin.handleFormat();
    this.updateActiveUnderline();
  },

  keyEvents: function(evt) {
    if (evt.keyCode === 27 || evt.which === 27) {
      this.close(this);
    }
  },

  toggleBarTrigger: function(evt,openStickySearchForm) {
    var _self = this;
    if (!_self.readonly &&
        ( ( _self.$el.find(evt.target).hasClass('js-date-to') && (!_self.$el.find(evt.target).hasClass('is-disabled')) )
          || _self.$el.find(evt.target).hasClass('js-flex-display') ||
            _self.$el.find(evt.target).hasClass('js-date-from')) ||
        _self.$el.find(evt.target).filter('label.l-date').length > 0) {
      return;
    }

    if(typeof _self.$parent.getTakeOverVariation === 'function') {
      //If variation is homepage search form expand search bar
      var searchFormVariation = _self.$parent.getTakeOverVariation();
      if (searchFormVariation === Constants.SEARCH_FORM.VARIATIONS.HOMEPAGE) {
        _self.$parent.expandSearchBar();
      }
    }

    var keycode = evt.keyCode || evt.which;
    if (keycode === 13 || evt.type === 'click') {
      _self.toggleBar(evt, _self);
    }
    _.defer(function(){
      if (_.isFunction(openStickySearchForm)) {
        _self.openFrom(evt);
        openStickySearchForm();
      }
    })
  },

  toggleBar: function(evt, _self) {
    /*open or close date/flex picker*/
    if (_self.$el.hasClass('is-flex-hidden')) {
      //is date
      if (this.$parent.responsiveUtils && this.$parent.responsiveUtils.isMobileOrTablet() && !this.suppressTakeOver) {
        this.openFrom(evt);
      }else {
        if (_self.$el.hasClass(_self.options.contActive)) {

          //is open, close it
          //if state is fromDate and to date is clicked dont close
          //if state is toDate and from date is clicked dont close
          if (!(_self.pickerState === 'from' && _self.$el.find(evt.target).hasClass('js-date-to')) &&
              !(_self.pickerState === 'to' && _self.$el.find(evt.target).hasClass('js-date-from'))) {
            _self.toggleLabel(null, _self);
            _self.close(_self);
          }
        } else {
          //is closed, open it
          setTimeout(function() {
            _self.from.$input.focus();
          });
        }
      }
    } else {
      //is flex
      if (this.$parent.responsiveUtils && this.$parent.responsiveUtils.isMobileOrTablet() && !this.suppressTakeOver) {
        this.takeoverScreen.init();
      }else {
        if (_self.$el.hasClass(_self.options.contActive)) {
          //is open, close it
          _self.close(_self);
        } else {
          //is closed, open it
          _self.$el.addClass(_self.options.contActive);
          _self.$dropdownIcon.addClass(_self.options.dropdownActive).removeClass(_self.options.dropdownInActive);

          _self.$dateFromToPicker.$holder.slideDown('medium');
          if (_self.$parent.responsiveUtils && !_self.$parent.responsiveUtils.isDevice()) {
            _self.flex.$monthTrigger.filter('.is-selected').focus();
          }
          _self.$dateFromToPicker.$root.addClass('picker--opened').attr('aria-hidden', 'false');
        }
      }
    }
  },

  close: function(_self, evt) {
    if (!evt || (evt && !_self.$el.find(evt.target).length)) {
      if (evt && $(evt.target).hasClass('picker__holder')) {
        return;
      }
      if(!_self.closeOnFormFieldsFocus) {
        return;
      }
      if (_self.$parent.responsiveUtils && !_self.$parent.responsiveUtils.isDevice() && _self.$parent.$el.closest('.is-sticky-top').length && _self.$el.hasClass('is-active')) {
        _self.pubsub.publish('SHOW_HWS_NAVIGATION_BAR');
      }
      _self.$dateFromToPicker.$root.removeClass('picker--opened').removeClass('picker--focused');
      _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
      _self.$dateFromToPicker.$holder.slideUp(300, function() {
        _self.from.$input.removeClass(_self.options.pickerActive);
        _self.to.$input.removeClass(_self.options.pickerActive);
        _self.$el.removeClass(_self.options.contActive);
        if(!_self.isBlurDisable){
          _self.from.$input.trigger('blur');
        }
      });
      _self.$el.find('.js-date-to').addClass('is-disabled');
      this.updateActiveUnderline(true);
    }
  }
};

module.exports = function (props){
  var obj = _.cloneDeep(properties);
  var DateHandlerInstance = ComponentMapper.extend(obj);
  return new DateHandlerInstance(props);
}
