var Config = {};

Config.TAKEOVER_HEADER_STYLING = {
  'font-weight': '600',
  'color': '#1c1c1c',
  'font-size': '30px',
  'line-height': '36px',
  'padding': '32px 0px',
  'width': '100%',
  'background-color': '#fff !important',
  'top': '-5px'
};

Config.ANDROID_INPUT_FIX = {
	'position': 'absolute',
	'bottom': '0',
	'width': '100%'
};

Config.REMOVE_ANDROID_INPUT_FIX = {
  'position': 'static',
  'width': '100%'
}

Config.TAKEOVER_VARIATION = {
	ADVANCED_SEARCH: {
		KEEP_OVERFLOW_HIDDEN: false
	},
	MODIFY_RES: {
		KEEP_OVERFLOW_HIDDEN: false
	},
	HEADER: {
		KEEP_OVERFLOW_HIDDEN: true
	},
	EDIT_SEARCH: {
		KEEP_OVERFLOW_HIDDEN: true
	},
	RATE_LIST_EDIT: {
		KEEP_OVERFLOW_HIDDEN: true
	},
	HOMEPAGE: {
		KEEP_OVERFLOW_HIDDEN: true
	},
	AVAILABILITY_SEARCH: {
		KEEP_OVERFLOW_HIDDEN: false
	},
	DEALS_AVAILABILITY_SEARCH: {
		KEEP_OVERFLOW_HIDDEN: false
	},
	CHECK_AVAILABILITY_HWS: {
		KEEP_OVERFLOW_HIDDEN: false
	}
}

module.exports = Config;