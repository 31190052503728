var $ = require('jquery');
var _ = require('lodash');
var ComponentMapper = require('./component-mapper');
var Constants = require('./constants');
var Config = require('./takeoverConfig');

var properties = {
  header: false,
  keepOverflowHidden: false,
  isActive: false,
  init: function() {
    var _self = this;
    var $backBtnSelector;
    
    this.setContent();
    if(!this.hidePageHeader && !this.header) {
      if (typeof this.customHeader === 'function') {
        this.customHeader();
      } else {
        this.createHeader();
      }
      this.header = true;
    }

    if(typeof this.backBtnSelector === "object") {
      $backBtnSelector = this.backBtnSelector;
    } else {
      $backBtnSelector = this.$el.find('.back-btn');
    }

    $backBtnSelector.off().on('click', _.bind(this.backButtonClick, this));
    this.show();
    this.isActive = true;
  },
  /**
  * This function is used to identify device orientation
  * @returns {boolean} device orientation is portrait or landscape
  */
  isPortrait: function() {
      return window.innerHeight > window.innerWidth;
  },
  /**
  * This function is used to identify device orientation
  * @returns {boolean} device orientation is portrait or landscape
  */
  ifBrowserIE11: function() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
    {
        //alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
        return true;
    }
 
    return false;
 //     return window.innerHeight > window.innerWidth;
  },
  /**
  * This function is used to set css for content in takeover screen
  */
  setContent: function() {
    this.cssProperties = {};
    this.cssProperties['position'] = this.$el.css('position');
    this.cssProperties['z-index'] = 0;
    this.cssProperties['margin'] = this.$el.css('margin');
    if(!this.isNotRetainDisplayAttribute) {
      this.cssProperties['display'] = this.$el.css('display');
    }
    this.cssProperties['top'] = this.$el.css('top');
    this.cssProperties['left'] = this.$el.css('left');
    this.cssProperties['padding'] = this.$el.css('padding');
    this.loadDimensions();

    if(this.ifBrowserIE11()) {
      this.$el.css(Config.internetExplorerDefaultTakeoverStyling);
    }else {
      this.$el.css(Config.defaultTakeoverStyling);
    }

    if (!this.skipDimensions) {
      this.$el.css({
        'width': Config.defaultWidth,
        'height': Config.defaultHeight
      })
    }
    this.$el.addClass('page-take-over');
    if(typeof this.setCustomContent === 'function'){
      this.setCustomContent();
    }
    if (this.transition_speed === undefined || this.transition_speed<0) {
      this.transition_speed = Constants.GLOBAL.TAKE_OVER_SPEED;
    }
  },
  /**
  *
  */
  loadDimensions: function(cssProperties) {
    if (!this.skipDimensions) {
      this.cssProperties['width'] = Config.defaultWidth;
      this.cssProperties['height'] = Config.defaultHeight;
    }
  },
  /**
  * This function is used to create header for take over screen
  */
  createHeader: function() {
      if (this.$el.find('.page-take-over-header').length) {
        this.$el.find('.page-take-over-header').remove();
      }
      this.$el.prepend('<div class="page-take-over-header"><span class="back-btn"></span>'+this.headerLabel+'</div>');
      this.$el.find('.page-take-over-header').css(Config.defaultHeaderStyling);
      this.$el.find('.back-btn').css(Config.defaultBackButtonStyling);
  },
  /**
  * This function handles back button click on take over screen
  * @param {Object} e Event for back button click.
  */
  backButtonClick: function(e) {
    e.preventDefault();
    e.stopPropagation();
    if(typeof this.backButtonCallback === 'function') {
      this.backButtonCallback();
    }
    this.hide();
    return false;
  },
  /**
  * This function is used to show take over screen with transition left to right
  */
  show: function() {
    var _self = this;
    this.$el.find('.page-take-over-header').show();
    if(typeof this.beforeShow === 'function') {
      this.beforeShow();
    }

    this.$el.animate({
      'left': 0
    }, this.transition_speed, function() {
      if (_self.onLoad) {
        _self.onLoad();
      }
      $('html,body').css(Config.setOverflow);
    });
  },
  /**
  * This function is used to hide take over screen with transition right to left
  */
  hide: function() {
    var _self = this;
    if (typeof this.beforeHide === 'function') {
      this.beforeHide();
    }
    this.isActive = false;
    this.$el.animate({
      'left': Config.defaultWidth
    }, this.transition_speed, function() {
      _self.$el.removeClass('page-take-over');
      if (!$('.page-take-over').length && !_self.keepOverflowHidden) {
        $('html,body').css(Config.removeOverflow);
      }
      if(typeof _self.afterHide === 'function') {
        _self.afterHide();
      }
      _self.$el.find('.page-take-over-header').hide();
      _self.$el.css(_self.cssProperties);
    })
  },
  /**
  * This function is used to create and show takeover screen without any transition
  */
  reload: function() {
    $('html,body').css(Config.setOverflow);
    this.$el.addClass('page-take-over');
    if (!this.cssProperties) {
      this.cssProperties = {};
      this.cssProperties['position'] = this.$el.css('position');
      this.cssProperties['z-index'] = 0;
      this.cssProperties['margin'] = this.$el.css('margin');
      this.cssProperties['top'] = this.$el.css('top');
      this.cssProperties['left'] = this.$el.css('left');
      this.cssProperties['padding'] = this.$el.css('padding');
      this.loadDimensions();
    }
    
    if(this.ifBrowserIE11()) {
      this.$el.css(Config.internetExplorerFinalTakeoverStyling);
    }else {
      this.$el.css(Config.finalTakeoverStyling);
    }

    if (!this.skipDimensions) {
      this.$el.css({
        'width': Config.defaultWidth,
        'height': Config.defaultHeight
      })
    }
    if(!this.hidePageHeader && !this.header) {
      if (typeof this.customHeader === 'function') {
        this.customHeader();
      }else {
        this.createHeader();
      }
      this.header = true;
    }
    this.$el.find('.back-btn').off().on('click', _.bind(this.backButtonClick, this));
    this.beforeShow();
    if (typeof this.onLoad === 'function') {
      this.onLoad();
    }
    this.isActive = true;
  },
  /**
  * This function is used to hide page takeover without transition
  */
  remove: function() {
    var _self = this;
    this.isActive = false;
    if (typeof _self.beforeHide === 'function') {
      _self.beforeHide();
    }
    _self.$el.removeClass('page-take-over');
    if (!$('.page-take-over').length) {
      $('html,body').css({
        'overflow': 'visible',
        'position': 'static',
        'height': Config.defaultHeight
      });
    }
    if(typeof _self.afterHide === 'function') {
      _self.afterHide();
    }
    _self.$el.find('.page-take-over-header').hide();
    _self.$el.css(_self.cssProperties);
  }
};

module.exports = {
  /**
  * This function is used to create instance of takeover screen
  */
  create: function(props) {
    var obj = _.cloneDeep(properties);
    var PageTakoverInstance = ComponentMapper.extend(obj);
    return new PageTakoverInstance(props);
  }
}